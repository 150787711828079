import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
    </PageDescription>
    <p>{`Portfolio management for software involves the management of a collection of software applications and projects
within an organization. The portfolio may include both new development projects as well as ongoing maintenance and
support for existing software applications. The primary goal of portfolio management is to ensure that the
software portfolio aligns with the organization’s strategic goals, optimizes the use of resources, and delivers
value to stakeholders.`}</p>
    <p>{`Portfolio management for software typically involves the following activities:`}</p>
    <ol>
      <li parentName="ol">{`Portfolio planning: This involves identifying the software projects that will be included in the portfolio, defining the scope and objectives of each project, and prioritizing the projects based on their strategic importance and potential value.`}</li>
      <li parentName="ol">{`Resource allocation: This involves determining the resources (people, budget, infrastructure, etc.) required to execute each project, and allocating resources in a way that optimizes the portfolio’s overall performance.`}</li>
      <li parentName="ol">{`Risk management: This involves identifying and managing risks associated with each project and the portfolio as a whole. This may include managing technical risks, financial risks, and risks related to changes in the business environment.`}</li>
      <li parentName="ol">{`Performance monitoring and reporting: This involves tracking the progress of each project and the portfolio as a whole, and reporting on key performance indicators such as cost, schedule, quality, and stakeholder satisfaction.`}</li>
      <li parentName="ol">{`Portfolio optimization: This involves making adjustments to the portfolio based on changes in the business environment or other factors. This may include adding or removing projects, adjusting priorities, or reallocating resources.`}</li>
    </ol>
    <p>{`Effective portfolio management for software can help organizations to achieve their strategic goals, improve resource utilization, reduce risk, and maximize the value of their software investments.`}</p>
    <h1>{`Overall`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-mermaid"
      }}>{`gantt
    title Digital Transformation Journey
    dateFormat  YYYY-MM-DD

    section Discovery
    Collect Inventory of Legacy Apps           :done, ila, 2020-01-01, 20d
    Create Target Architecture     :crit, done, tarch, 2020-01-07, 15d
    Inventory of Target Apps     :done, ita, 2020-01-18, 10d
    Map to Target Apps     :crit, active, mta, 2020-01-24, 7d

    section Implementation
    Project A      :active, pa, after mta, 12d
    Go-Live        :milestone, gla
    Warranty       :wa, after pa, 30d
    Project B      :pb, after pa, 5d
    Go-Live        :milestone, glb
    Warranty       :wb, after pb, 30d
    Project C      :crit, active, pc, after mta, 22d
    Go-Live        :crit, milestone, glc
    Warranty       :crit, wc, after pc, 30d

    section Decommission
    Legacy Apps - Project A      :da, after wa, 12d
    Legacy Apps - Project B      :db, after wb, 12d
    Legacy Apps - Project C      :crit, dc, after wc, 12d

    section Closure
    Project A      :ca, after da, 12d
    Project B      :cb, after db, 12d
    Project C      :crit, cc, after dc, 12d
    Program        :after ca cb cc, 10d
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      